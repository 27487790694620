import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [{
        path: '*',
        name: '404',
        component: () =>
            import ('@/views/NotFound.vue')
    },

    {
        //Sluzi za redirect sa pozvane stranice na neku drugu
        path: '/',
        redirect: 'about'
    },
    {
        //NESTED ROUTES !!! Posto je u sklopu Home componenter i ima "children" parametar znaci da ce u njemu biti prikazana componenta, 
        //pod uslovom da u Home componenti negde stavimo  <router-view></router-view> ...vise informacija na:
        // https://router.vuejs.org/guide/essentials/nested-routes.html
        path: '/home',
        redirect: 'about',
        component: Home,
        children: [{
                path: '/about',
                name: 'About',
                component: () =>
                    import ('../views/About.vue')
            },
            {
                path: '/projects',
                name: 'Projects',
                component: () =>
                    import ('../views/Projects.vue')
            },
            {
                path: '/experience',
                name: 'Experience',
                component: () =>
                    import ('../views/Experience.vue')
            },
            {
                path: '/education',
                name: 'Education',
                component: () =>
                    import ('../views/Education.vue')
            },
            {
                path: '/contact',
                name: 'Contact',
                component: () =>
                    import ('../views/Contact.vue')
            },
            {
                path: '/superlab',
                name: 'SuperLab',
                component: () =>
                    import ('../views/Projects/SuperLab.vue')
            },
            {
                path: '/holiday-planner',
                name: 'HolidayPlanner',
                component: () =>
                    import ('../views/Projects/Holiday-planner.vue')
            },
            {
                path: '/calculator',
                name: 'Calculator',
                component: () =>
                    import ('../views/Projects/Calculator.vue')
            },
            {
                path: '/cookBook',
                name: 'Cookbook',
                component: () =>
                    import ('../views/Projects/CookBook.vue')
            },
            {
                path: '/bakery',
                name: 'Bakery',
                component: () =>
                    import ('../views/Projects/Bakery.vue')
            },
            {
                path: '/myRecipes',
                name: 'MyRecipes',
                component: () =>
                    import ('../views/Projects/MyRecipes.vue')
            },
            {
                path: '/tacna',
                name: 'Tacna',
                component: () =>
                    import ('../views/Projects/Tacna.vue')
            },
            {
                path: '/tetsu',
                name: 'Tetsu',
                component: () =>
                    import ('../views/Projects/Tetsu.vue')
            },

        ]
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    //kada scroll na dnu i kliknem na poslednji projekat on zbog transition otvori projekat ali scroll ga tamo gde sam na 
    //predhodnu stranicu kliknuo ovaj block koda scrollBehavior to resava. Isto tako i kada idem na back on vrati tacno na poziciju
    //na kojoj sam bio pre nego sto sam kliknuo da udjem na neki projekat. Inace u tag <transition> se pise @before-enter="beforeEnter"
    //i onda se u istom fajlu u metodi pise
    //methods: {
    // beforeEnter () {
    // this.$root.$emit('scrollBeforeEnter');
    // }
    //}
    // pojasnjeno malo bolje https://forum.vuejs.org/t/vue-router-page-position-when-navigating-pages/32885/3
    scrollBehavior(to, from, savedPosition) {
        // Default scroll position will be 0, 0 unless overridden by a saved position
        const position = {
            x: 0,
            y: 0
        };

        // Override default with saved position (if it exists)
        if (savedPosition) {
            position.x = savedPosition.x;
            position.y = savedPosition.y;
        }

        // Listen for scrollBeforeEnter event and set scroll position
        return new Promise(resolve => {
            this.app.$root.$once("scrollBeforeEnter", () => {
                resolve(position);
            });
        });
    }
})

export default router