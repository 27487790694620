var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"frame",style:(_vm.$vuetify.breakpoint.smAndDown
  ? "display:flex; justify-content: center; align-items: center; gap: 10px; margin: 30px 0 0;"
  : "position:relative; text-align: center; ")},[_c('v-img',{style:(_vm.$vuetify.breakpoint.smAndDown ? "display: none;" : "max-width:100%"),attrs:{"src":require("../assets/nova-ver3.jpg"),"alt":"Vasic Igor photo"}}),_c('div',{style:(_vm.$vuetify.breakpoint.smAndDown
  ? ""
  : "width: 100%; position: absolute; left: 50%; bottom: 10%; transform: translate(-50%);")},[_c('h1',{staticClass:"ime",staticStyle:{"font-weight":"100"},style:(_vm.$vuetify.breakpoint.smAndDown
  ? "font-size: 1.9em; text-align: center; text-transform: uppercase;"
  : "font-size: 1.9em;")},[_c('span',{staticStyle:{"font-weight":"600"}},[_vm._v("Igor")]),_vm._v(" Vasić ")]),_c('p',{staticClass:"infos",staticStyle:{"font-size":"1.1em","font-weight":"500"}},[_vm._v(" Front-End Developer ")]),_c('div',{staticClass:"perinfo",style:(_vm.$vuetify.breakpoint.smAndDown
  ? "margin-top: 10px"
  : "margin-top: 20px")},[_c('div',{staticClass:"icons",staticStyle:{"display":"flex","justify-content":"center","align-items":"center","gap":"10px"}},[_c('a',{attrs:{"href":"https://www.linkedin.com/in/igor-vasic-8a2b44171/","target":"_blank"}},[_c('i',{staticClass:"socicon linkedIn fab fa-linkedin-in",style:(_vm.$vuetify.breakpoint.smAndDown
  ? "font-size: 14px; text-align: center"
  : "font-size: 20px;")})]),_c('a',{attrs:{"href":"https://www.github.com/kirk7183/","target":"_blank"}},[_c('i',{staticClass:"socicon github fab fa-github",style:(_vm.$vuetify.breakpoint.smAndDown
  ? "font-size: 14px; text-align: center"
  : "font-size: 20px;")})])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }