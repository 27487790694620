var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"fill-height",attrs:{"fluid":""}},[_c('v-row',{staticStyle:{"padding":"0","margin":"0"},attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticStyle:{"padding":"0px","max-width":"1200px"},attrs:{"cols":"12","sm":"8","md":"10"}},[_c('v-card',{staticClass:"elevation-0",attrs:{"color":"#1a1b1d"}},[_c('v-card-text',{class:_vm.$vuetify.breakpoint.smAndDown ? "pa-0" : "pt-3"},[_c('v-row',{class:{ 'ma-0': _vm.$vuetify.breakpoint.smAndDown },attrs:{"justify":"center","align":"center"}},[_c('v-col',{staticClass:"pa-0 mr-0 justify-center",class:{ 'd-flex': _vm.$vuetify.breakpoint.smAndDown },attrs:{"cols":"8","md":"3","align-self":"center"}},[_c('leftSide')],1),_c('v-col',{staticClass:"pa-0 mt-10",attrs:{"cols":"11","md":"9"}},[_c('v-toolbar',{attrs:{"color":"#1a1b1d","dark":"","flat":"","dense":""}},[_c('v-toolbar-title',{staticClass:"mx-auto",staticStyle:{"border-bottom":"1px solid white"}},[_c('div',{staticClass:"icons"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('i',_vm._g(_vm._b({staticClass:"navicon far fa-user ma-2",style:({ color: _vm.buttonColor[0] }),on:{"click":function($event){_vm.router('about'), (_vm.activeButton = 0)}}},'i',attrs,false),on))]}}])},[_c('span',[_vm._v("About me")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('i',_vm._g(_vm._b({staticClass:"navicon fas fa-glasses ma-2",style:({ color: _vm.buttonColor[1] }),on:{"click":function($event){_vm.router('projects'), (_vm.activeButton = 1)}}},'i',attrs,false),on))]}}])},[_c('span',[_vm._v("Projects preview")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('i',_vm._g(_vm._b({staticClass:"navicon fas fa-toolbox ma-2",style:({ color: _vm.buttonColor[2] }),on:{"click":function($event){_vm.router('experience'), (_vm.activeButton = 2)}}},'i',attrs,false),on))]}}])},[_c('span',[_vm._v("Experience")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('i',_vm._g(_vm._b({staticClass:"navicon fas fa-book-open ma-2",style:({ color: _vm.buttonColor[3] }),on:{"click":function($event){_vm.router('education'), (_vm.activeButton = 3)}}},'i',attrs,false),on))]}}])},[_c('span',[_vm._v("Education")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('i',_vm._g(_vm._b({staticClass:"navicon fas fa-phone-alt ma-2",style:({ color: _vm.buttonColor[4] }),on:{"click":function($event){_vm.router('contact'), (_vm.activeButton = 4)}}},'i',attrs,false),on))]}}])},[_c('span',[_vm._v("Contact")])])],1)])],1),_c('transition',{attrs:{"name":"fade","mode":"out-in"},on:{"before-enter":_vm.beforeEnter}},[_c('router-view')],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }