<template>
  <div class="frame" :style="$vuetify.breakpoint.smAndDown
    ? `display:flex; justify-content: center; align-items: center; gap: 10px; margin: 30px 0 0;`
    : `position:relative; text-align: center; `
    ">
    <!-- <v-img :style="$vuetify.breakpoint.smAndDown ? `max-width:50px;` : `max-width:100%` -->
    <v-img :style="$vuetify.breakpoint.smAndDown ? `display: none;` : `max-width:100%`
    " src="../assets/nova-ver3.jpg" alt="Vasic Igor photo" />
    <div :style="$vuetify.breakpoint.smAndDown
    ? ``
    : `width: 100%; position: absolute; left: 50%; bottom: 10%; transform: translate(-50%);`
    ">
      <h1 class="ime" style="font-weight: 100" :style="$vuetify.breakpoint.smAndDown
    ? `font-size: 1.9em; text-align: center; text-transform: uppercase;`
    : `font-size: 1.9em;`
    ">
        <span style="font-weight: 600;">Igor</span> Vasić
      </h1>
      <p class="infos" style="font-size: 1.1em; font-weight: 500">
        Front-End Developer
      </p>
      <div class="perinfo" :style="$vuetify.breakpoint.smAndDown
    ? `margin-top: 10px`
    : `margin-top: 20px`
    ">
        <!-- SOCIAL NETWORKS -->
        <div class="icons" style="
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;
          ">
          <!-- <a href="https://www.facebook.com/igor.k.vasic.9" target="_blank">
            <i
              class="socicon fab fa-facebook-f"
              :style="
                $vuetify.breakpoint.smAndDown
                  ? `font-size: 14px; text-align: center` 
                  : `font-size: 20px;`
              "
            ></i
          ></a> -->
          <a href="https://www.linkedin.com/in/igor-vasic-8a2b44171/" target="_blank">
            <i class="socicon linkedIn fab fa-linkedin-in" :style="$vuetify.breakpoint.smAndDown
    ? `font-size: 14px; text-align: center`
    : `font-size: 20px;`
    "></i></a>
          <a href="https://www.github.com/kirk7183/" target="_blank">
            <i class="socicon github fab fa-github" :style="$vuetify.breakpoint.smAndDown
    ? `font-size: 14px; text-align: center`
    : `font-size: 20px;`
    "></i></a>
          <!-- <a
            href="https://www.instagram.com/kirk_zmajeva_kugla/?hl=en"
            target="_blank"
          >
            <i
              class="socicon last fab fa-instagram"
              :style="
                $vuetify.breakpoint.smAndDown
                  ? `font-size: 14px; text-align: center `
                  : `font-size: 20px; `
              "
            ></i
          ></a> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
@media screen and (max-width: 959px) {
  .socicon {
    width: 35px;
  }
}
</style>
