import "@fortawesome/fontawesome-free/css/all.css";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueLazyLoad from "vue-lazyload";
import firebase from "firebase/app";
import "firebase/storage";
import "firebase/analytics";

// Vue.use(firebase)
Vue.use(VueLazyLoad);

Vue.config.productionTip = false;

Vue.use(vuetify, {
  iconfont: "fa",
});

var firebaseConfig = {
  apiKey: "AIzaSyB4Q3DhhVbKlOOX2gUA2VFj6QveDjgz0KU",
  authDomain: "portfolio---igor-vasic.firebaseapp.com",
  databaseURL: "https://portfolio---igor-vasic.firebaseio.com",
  projectId: "portfolio---igor-vasic",
  storageBucket: "portfolio---igor-vasic.appspot.com",
  messagingSenderId: "1017584766260",
  appId: "1:1017584766260:web:41b8aa3bba97dc47356961",
  measurementId: "G-XT8VD5QN3Q",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
