<template>
  <v-app>
    <v-main>
      <!-- <home></home> -->
      <!-- <about></about> -->
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
// import Home from "./views/Home.vue";
// import About from "./views/About.vue";
export default {
  name: "App",
  // Home
  // components: { About },

  data: () => ({
    //
  }),
};
</script>
<style src="./Css/Style.css"
 ></style>
