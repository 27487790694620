<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center" style="padding: 0; margin: 0">
      <v-col cols="12" sm="8" md="10" style="padding: 0px; max-width: 1200px">
        <v-card class="elevation-0" color="#1a1b1d">
          <v-card-text :class="$vuetify.breakpoint.smAndDown ? `pa-0` : `pt-3`">
            <v-row justify="center" align="center" :class="{ 'ma-0': $vuetify.breakpoint.smAndDown }">
              <!-- 1 codition -->
              <v-col cols="8" md="3" class="pa-0 mr-0 justify-center" align-self="center"
                :class="{ 'd-flex': $vuetify.breakpoint.smAndDown }">
                <!-- IMPORT COMPONENTS -->
                <!-- SLIKA -->
                <leftSide></leftSide>
              </v-col>

              <!-- TOOLBAR -->
              <v-col cols="11" md="9" class="pa-0 mt-10">
                <v-toolbar color="#1a1b1d" dark flat dense>
                  <v-toolbar-title class="mx-auto" style="border-bottom: 1px solid white">
                    <div class="icons">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <i class="navicon far fa-user ma-2" @click="router('about'), (activeButton = 0)"
                            :style="{ color: buttonColor[0] }" v-bind="attrs" v-on="on">
                          </i>
                        </template>
                        <span>About me</span>
                      </v-tooltip>

                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }" class="hidden-sm-and-down">
                          <i class="navicon fas fa-glasses ma-2" v-bind="attrs" v-on="on"
                            @click="router('projects'), (activeButton = 1)" :style="{ color: buttonColor[1] }"></i>
                        </template>
                        <span>Projects preview</span>
                      </v-tooltip>

                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <i class="navicon fas fa-toolbox ma-2" @click="router('experience'), (activeButton = 2)"
                            :style="{ color: buttonColor[2] }" v-bind="attrs" v-on="on"></i>
                        </template>
                        <span>Experience</span>
                      </v-tooltip>

                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <i class="navicon fas fa-book-open ma-2" @click="router('education'), (activeButton = 3)"
                            :style="{ color: buttonColor[3] }" v-bind="attrs" v-on="on"></i>
                        </template>
                        <span>Education</span>
                      </v-tooltip>

                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <i class="navicon fas fa-phone-alt ma-2" @click="router('contact'), (activeButton = 4)"
                            :style="{ color: buttonColor[4] }" v-bind="attrs" v-on="on"></i>
                        </template>
                        <span>Contact</span>
                      </v-tooltip>
                    </div>
                  </v-toolbar-title>
                </v-toolbar>

                <!-- ROUTER VIEW-->
                <transition name="fade" mode="out-in" @before-enter="beforeEnter">
                  <router-view></router-view>
                </transition>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import router from "@/router";
  import leftSide from "../components/left-side.vue";
  export default {
    components: { leftSide },
    data() {
      return {
        activeButton: 0,
      };
    },
    created() {
      if (this.$route.path == "/" || this.$route.path == "/home") {
        this.activeButton = 0;
        router.replace("/about");
      } else if (
        this.$route.path == "/projects" ||
        this.$route.path == "/superlab" ||
        this.$route.path == "/cookBook" ||
        this.$route.path == "/bakery" ||
        this.$route.path == "/myRecipes"
      ) {
        this.activeButton = 1;
      } else if (this.$route.path == "/experience") {
        this.activeButton = 2;
      } else if (this.$route.path == "/education") {
        this.activeButton = 3;
      } else if (this.$route.path == "/contact") {
        this.activeButton = 4;
      }
    },
    computed: {
      buttonColor: function () {
        let result = [];
        for (var i = 0; i < 5; i++) {
          if (this.activeButton == i) {
            result.push("#fff");
          } else {
            result.push("#51b57f");
          }
        }

        return result;
      },
    },

    methods: {
      router(value) {
        if (value == "about") {
          router.replace("/about");
        } else if (value == "projects") {
          router.replace("/projects");
        } else if (value == "experience") {
          router.replace("/experience");
        } else if (value == "education") {
          router.replace("/education");
        } else if (value == "education") {
          router.replace("/education");
        } else if (value == "contact") {
          router.replace("/contact");
        }
      },
      beforeEnter() {
        this.$root.$emit("scrollBeforeEnter");
      },
    },
  };
</script>

<style scoped>
  .fade-enter-active,
  .fade-leave-active {
    transition-duration: 0.3s;
    transition-property: opacity;
    transition-timing-function: ease;
  }

  .fade-enter,
  .fade-leave-active {
    opacity: 0;
  }
</style>
